<script setup
        lang="ts">

</script>

<template>
   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <mask id="mask0_90_153" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
      <rect width="20" height="20" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_90_153)">
      <path d="M3.33335 18.3333C2.87502 18.3333 2.48266 18.1701 2.15627 17.8438C1.82988 17.5174 1.66669 17.125 1.66669 16.6667V3.33333C1.66669 2.875 1.82988 2.48264 2.15627 2.15625C2.48266 1.82986 2.87502 1.66667 3.33335 1.66667H10.125L8.45835 3.33333H3.33335V16.6667H12.5V15H14.1667V16.6667C14.1667 17.125 14.0035 17.5174 13.6771 17.8438C13.3507 18.1701 12.9584 18.3333 12.5 18.3333H3.33335ZM5.00002 15V13.3333H10.8334V15H5.00002ZM5.00002 12.5V10.8333H9.16669V12.5H5.00002ZM12.5 12.5L9.16669 9.16667H6.66669V5H9.16669L12.5 1.66667V12.5ZM14.1667 9.95833V4.20833C14.6667 4.5 15.0695 4.89583 15.375 5.39583C15.6806 5.89583 15.8334 6.45833 15.8334 7.08333C15.8334 7.70833 15.6806 8.27083 15.375 8.77083C15.0695 9.27083 14.6667 9.66667 14.1667 9.95833ZM14.1667 13.5417V11.7917C15.1389 11.4444 15.9375 10.8438 16.5625 9.98958C17.1875 9.13542 17.5 8.16667 17.5 7.08333C17.5 6 17.1875 5.03125 16.5625 4.17708C15.9375 3.32292 15.1389 2.72222 14.1667 2.375V0.625C15.6111 1 16.8056 1.78125 17.75 2.96875C18.6945 4.15625 19.1667 5.52778 19.1667 7.08333C19.1667 8.63889 18.6945 10.0104 17.75 11.1979C16.8056 12.3854 15.6111 13.1667 14.1667 13.5417Z" fill="currentColor"/>
    </g>
  </svg>
</template>

<style scoped>

</style>